<template>
    <div>
        
        <button
            class="vs-component vs-button rounded-full vs-button-filled"
            :class="{
                'bg-primary text-white' : route === '/app/promotions/banners',
                'bg-white text-primary' : route !== '/app/promotions/banners',
            }"
            @click="toggleRoute()"
        > <feather-icon icon="PlusIcon" class="align-middle mr-2"/> {{ this.$t('Banners') }} </button> 

        <router-view class="mt-10"></router-view>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({

    created() {
        this.$store.dispatch( 'updatePageTitle', {
            title    : this.$t('Banners Promocionales'),
            subtitle : this.$t('Agrega y Administra tus banners')
        });
    },

    methods : {
        toggleRoute() {
            if( this.route === '/app/promotions/banners' ) this.$router.push( { path: '/app/promotions/banners/new' } );
            else this.$router.push( { path: '/app/promotions/banners' } );
        },
    },

    computed : {
        route() {
            return this.$route.path;
        }
    }
})
</script>
